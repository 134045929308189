// @ts-nocheck
import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Card, Row, Col, Alert } from "antd";
import React, { useState } from "react";
import ReactOnRails from 'react-on-rails';
import { useIntl } from "react-intl";
import { UserType } from "../../types";
import castAttributesFromModel, { ModelDefinitionType } from "../../../utils/castAttributesFromDefinition";
import { useHistory } from 'react-router-dom'
import logo from "../../images/logobox41.svg"
import axiosClient from "../../../utils/axiosClient";

type SignInProps = {
  user: UserType;
  errors: any;
};

const userDefinition: ModelDefinitionType = {
  email: 'String',
  password: 'Password',
};

const SignIn: React.FC<SignInProps> = props => {
  const {
    user,
  } = props;


  const [errors, setErrors] = useState(undefined)

  const history = useHistory(undefined);

  const onSubmit = () => {
    const params =  castAttributesFromModel(userDefinition, object)
    params['authenticity_token'] = ReactOnRails.authenticityToken() || ""
    
    axiosClient.post('/users/sign_in', {user: params})
      .then((response) => {
        window.location.href = '/order_items'
      }).catch((error) => {
        setErrors(error[0])
      })
  }

  const { object, onChange } = useAntForm(user || {});
  const intl = useIntl();

  const SignInSchema: AntSchema = [
    (errors) ? 
      <Alert message={errors.message} type="error" style={{marginBottom: 7}}/> 
    : null,
    {
      name: 'email',
      label: intl.formatMessage({ id: 'words.email' }),
      input: {
        type: 'string',
      },
      colProps: {
        xs: 24,
        md: 24,
      },
    },
    {
      name: 'password',
      label: intl.formatMessage({ id: 'words.password' }),
      input: {
        type: 'password',
      },
      colProps: {
        xs: 24,
        md: 24,
      },
    },
  ]

  return (
    <div className='home-container'>
      <Row justify="center">
        <Col className='signin-wrapper' sm={24} md={16} lg={8}>
          <Card>
            <div className="flex justify-content-center mb-5">
              <img src={logo} height="50" />
            </div>
            <AntForm
              schema={SignInSchema}
              object={object}
              layout="vertical"
              onChange={onChange}
              onSubmit={onSubmit}
              errors={errors}
              submitText={intl.formatMessage({ id: 'words.login' })}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
};

export default SignIn;